<template>
  <validation-observer ref="inspirationObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja inspiracji</h4>

        <b-form-group class="mt-2" label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="mt-2"
          label="Klucz unikalny inspiracji (slug - pozostawienie pustego pola spowoduje automatyczne wygenerowanie sluga)">
          <validation-provider
            #default="{ errors }"
            name="Klucz unikalny inspiracji (slug)"
            rules="alpha_dash|min:4|max:120">
            <b-form-input
              id="v-slug"
              placeholder="Klucz unikalny inspiracji (slug)"
              v-model="form.slug"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Tagi">
          <validation-provider #default="{ errors }" name="Tagi">
            <v-select
              id="v-category"
              label="name"
              placeholder="Wpisz tagi i zatwierdź enterem"
              v-model="form.tags"
              :taggable="true"
              :clearable="false"
              :multiple="true"
              :deselectFromDropdown="false"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Opis SEO">
          <validation-provider #default="{ errors }" name="Opis SEO" rules="required|min:2|max:160">
            <b-form-textarea v-model="form.seo_description" :disabled="!lang" rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Powiązane posty">
          <v-select
            label="title"
            name="inspiration-posts"
            placeholder="Wybierz posty"
            v-model="form.posts"
            id="v-post-selection"
            :clearable="false"
            :closeOnSelect="false"
            :multiple="true"
            :deselectFromDropdown="false"
            :options="availablePosts" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Treść inspiracji</h4>

        <b-form-group class="mt-2" label="Tytuł">
          <validation-provider #default="{ errors }" name="Tytuł" rules="required|min:2|max:150">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.name" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-0" label="Zdjęcie tytułowe">
          <b-button @click="toggleShowModal('image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie tytułowe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.image" type="hidden" name="Zdjęcie tytułowe" v-model="files.image.file" />
            <input v-else type="hidden" name="Zdjęcie tytułowe" v-model="files.image" />

            <ImageSelector
              v-if="showImagesModal.image"
              :files="filesArray"
              :show-modal="showImagesModal.image"
              @closeModal="toggleShowModal('image')"
              @submit="(fileList) => handleFile(fileList[0], 'image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview v-if="files.image" :image="files.image" @deleteImage="() => handleDelete('image')" />
        </b-form-group>

        <b-form-group label="Krótki opis">
          <validation-provider #default="{ errors }" name="Krótki opis" rules="required|min:3|max:400">
            <b-form-textarea v-model="form.short_description" :disabled="!lang" rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group class="mt-2" label="Treść">
          <validation-provider #default="{ errors }" name="Treść" rules="required">
            <quill-editor v-model="form.description" :options="editorOption" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Form repeater -->
      <b-col>
        <h4 class="mb-2 mt-3">Produkty w inspiracji</h4>

        <b-form-group label="Produkty">
          <b-col v-for="(item, index) in form.products" :id="item.id" :key="item.id" ref="row">
            <b-form-group label="SKU" :label-for="`year-${item.id}-${item.id}`">
              <validation-provider #default="{ errors }" :name="`SKU #${index}`" rules="required">
                <v-select
                  v-model="form.products[index].product_symbol"
                  :id="`product-symbol-${item.id}-${item.id}`"
                  placeholder="SKU"
                  label="sku"
                  :multiple="false"
                  :closeOnSelect="true"
                  :options="skuProducts"
                  @search="fetchBySku" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="X%" :label-for="`x-${item.id}-${item.id}`">
              <validation-provider #default="{ errors }" :name="`X #${index}`" rules="required">
                <b-form-input
                  v-model="form.products[index].x"
                  type="number"
                  :id="`x-${item.id}-${item.id}`"
                  placeholder="X%" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Y%" :label-for="`y-${item.id}-${item.id}`">
              <validation-provider #default="{ errors }" :name="`X #${index}`" rules="required">
                <b-form-input
                  v-model="form.products[index].y"
                  type="number"
                  :id="`y-${item.id}-${item.id}`"
                  placeholder="Y%" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div v-if="files.image" class="position-relative">
              <img :src="files.image.preview" class="img-fluid" />
              <div
                class="image-dot"
                :style="{
                  top: `${form.products[index].y}%`,
                  left: `${form.products[index].x}%`,
                }"></div>
            </div>

            <!-- Remove Button -->

            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              :disabled="index === 0"
              @click="removeItem(index)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Usuń</span>
            </b-button>

            <b-col>
              <hr />
            </b-col>
          </b-col>

          <b-col>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              @click="addProductElement"
              :disabled="!lang">
              <span>Dodaj produkt</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          :disabled="!lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import { getLanguageByLang } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

import { quickSearch } from '@/http/product/product';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    inspiration: {
      type: Object,
      default: () => {},
    },
    posts: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    quillEditor,
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      lang: null,
      form: {
        name: '',
        image: null,
        short_description: '',
        description: '',
        tags: [],
        products: [],
        posts: [],

        seo_description: '',
        slug: '',
      },
      languages: getLanguages(),
      skuProducts: [],
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
    };
  },

  created() {
    this.setFileUploadData(['image'], ['image']);
  },

  watch: {
    async inspiration(newValue) {
      this.lang = getLanguageByLang(newValue.lang);
      await this.setImage(this.inspiration.image, 'image');

      newValue = {
        ...newValue,
        products: newValue.markers.map((marker) => ({
          id: marker.id,
          product_symbol: marker.product.sku,
          x: marker.x,
          y: marker.y,
        })),
      };

      this.form = newValue;
    },
    files: {
      handler(value) {
        if (!value.image) {
          this.form.products = [];
        }
      },
      deep: true,
    },
  },

  methods: {
    fetchBySku(sku) {
      if (!sku) return;
      setTimeout(async () => {
        try {
          const { data } = await quickSearch('pl', sku);
          this.skuProducts = data.data;
        } catch (error) {
          this.showErrorNotification('Problem z pobraniem produktów', error.response.data.message);
        }
      }, 500);
    },
    addProductElement() {
      if (!this.files.image) {
        return this.showErrorNotification('Najpierw dodaj zdjęcie inspiracji');
      }

      this.form.products.push({
        id: new Date().toISOString(),
        product_symbol: '',
        x: '',
        y: '',
      });
    },
    removeItem(index) {
      this.$delete(this.form.products, index);
    },
    onSubmit() {
      const data = {
        ...this.form,
        image: this.files.image,
        lang: this.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.inspirationObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    resetLanguage() {
      this.lang = null;
      this.form.lang = null;
    },
  },

  computed: {
    availablePosts() {
      if (!this.lang || !this.posts.length) {
        return [];
      }

      return this.posts.filter((post) => post.lang === this.lang.lang);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.image-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: red;
}
</style>
